import styled from "styled-components";

export const MyModal = styled.article`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (!props.isOpen ? "none" : "flex")};
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  background-color: #fff;
  min-width: 320px;
  max-width: 800px;
  min-height: 200px;
  max-height: 800px;
  overflow-y: auto;
  border-radius: 1.5rem;
  padding: 2.5rem;
  animation: ${(props) =>
    props.isOpen
      ? "scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both"
      : "scale-down-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both"};

  @media screen and (max-width: 800px) {
    min-width: 320px;
    max-width: 600px;
    min-height: 200px;
    max-height: 600px;
  }

  @media screen and (max-width: 330px) {
    min-width: 250px;
    max-width: 600px;
    min-height: 200px;
    max-height: 600px;
  }

  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes scale-down-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }

  p {
    margin-bottom: 1rem;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-style: none;
  background-color: #fff;
  cursor: pointer;
  padding: 0.2rem;
  border-radius: 0.2rem;
  font-size: 15px;
  text-align: center;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: rgba(53, 58, 68, 0.05);
  }
`;

export const ModalHeader = styled.div`
  font-size: 20px;
  color: rgb(53, 58, 68);
  border-bottom: 2px solid rgb(53, 58, 68);
  margin-bottom: 1rem;

  h3 {
    padding-bottom: 0.5rem;
  }
`;

export const ModalBody = styled.div`
  color: rgb(53, 58, 68);
  text-align: justify;
  ol li {
    list-style-position: outside;
    margin-bottom: 0.5rem;

    &::marker {
      font-weight: bold;
    }
  }

  li ul li {
    margin-left: 1rem;
    margin-bottom: 0;
  }
`;

export const ModalImg = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const Img = styled.img`
  width: 250px;
  height: auto;
`;

export const AcceptButton = styled.button`
  background-color: #3b2456;
  color: #fff;
  border-style: none;
  cursor: pointer;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 2rem;
  font-size: 15px;
  transition: 0.2 ease-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 2rem;
`;
