import client_1 from "../assets/images/clients/client_1.png";
import client_2 from "../assets/images/clients/client_2.png";
import client_3 from "../assets/images/clients/client_3.png";
import client_4 from "../assets/images/clients/client_4.png";
import client_5 from "../assets/images/clients/client_5.png";
import client_6 from "../assets/images/clients/client_6.png";
import client_7 from "../assets/images/clients/client_7.png";
import client_8 from "../assets/images/clients/client_8.png";
import client_9 from "../assets/images/clients/client_9.png";
import client_10 from "../assets/images/clients/client_10.png";
import client_11 from "../assets/images/clients/client_11.png";
import client_12 from "../assets/images/clients/client_12.png";
import client_13 from "../assets/images/clients/client_13.png";
import client_14 from "../assets/images/clients/client_14.png";
import client_15 from "../assets/images/clients/client_15.png";
import client_16 from "../assets/images/clients/client_16.png";
import client_17 from "../assets/images/clients/client_17.png";
import client_18 from "../assets/images/clients/client_18.png";
import client_19 from "../assets/images/clients/client_19.png";
import client_20 from "../assets/images/clients/client_20.png";
import client_21 from "../assets/images/clients/client_21.png";
import client_22 from "../assets/images/clients/client_22.png";

export const clientsData = [
  {
    client: {
      clientName: "Mutual Ser",
      clientImg: client_1,
      clientImgAlt: "Mutual Ser",
      clientUrl: "https://www.mutualser.com/",
    },
  },
  {
    client: {
      clientName: "AMBUQ EPS",
      clientImg: client_2,
      clientImgAlt: "AMBUQ EPS",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Medimás EPS",
      clientImg: client_3,
      clientImgAlt: "Medimás EPS",
      clientUrl: "https://medimas.com.co/",
    },
  },
  {
    client: {
      clientName: "Salud Vida EPS",
      clientImg: client_4,
      clientImgAlt: "Salud Vida EPS",
      clientUrl: "https://www.saludvidaeps.com/",
    },
  },
  {
    client: {
      clientName: "NOVASALUD IPS",
      clientImg: client_5,
      clientImgAlt: "NOVASALUD IPS",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Fundación CAMINOS IPS",
      clientImg: client_6,
      clientImgAlt: "Fundación CAMINOS IPS",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Unidad Médica Odontológica IPS Los Alpes",
      clientImg: client_7,
      clientImgAlt: "IPS Los Alpes",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Integral Health Institute",
      clientImg: client_8,
      clientImgAlt: "Integral Health",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Villasalud",
      clientImg: client_9,
      clientImgAlt: "Villasalud",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "SOMEDYT IPS",
      clientImg: client_10,
      clientImgAlt: "SOMEDYT IPS",
      clientUrl: "https://somedyt.com/",
    },
  },
  {
    client: {
      clientName: "Development and Technologies Solutions",
      clientImg: client_11,
      clientImgAlt: "Development and Technologies Solutions",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "ISO del Caribe",
      clientImg: client_12,
      clientImgAlt: "ISO del Caribe",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "ASESODES",
      clientImg: client_13,
      clientImgAlt: "ASESODES",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Dataclinic",
      clientImg: client_14,
      clientImgAlt: "Dataclinic",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Datalimpics",
      clientImg: client_15,
      clientImgAlt: "Datalimpics",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "VEN",
      clientImg: client_16,
      clientImgAlt: "VEN",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Universal Aluminios Del Caribe",
      clientImg: client_17,
      clientImgAlt: "Universal Aluminios Del Caribe",
      clientUrl: "http://www.universalaluminios.com/",
    },
  },
  {
    client: {
      clientName: "Merken Food Gourmet",
      clientImg: client_18,
      clientImgAlt: "Merken Food Gourmet",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Todos contra el covid",
      clientImg: client_19,
      clientImgAlt: "Todos contra el covid",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Raad Berrio",
      clientImg: client_20,
      clientImgAlt: "Raad Berrio",
      clientUrl: "http://www.raadberrio.com/",
    },
  },
  {
    client: {
      clientName: "La Arete",
      clientImg: client_21,
      clientImgAlt: "La Arete",
      clientUrl: "",
    },
  },
  {
    client: {
      clientName: "Cinemateca Del Caribe",
      clientImg: client_22,
      clientImgAlt: "Cinemateca Del Caribe",
      clientUrl: "https://cinematecadelcaribe.com/",
    },
  },
];

export default clientsData;
