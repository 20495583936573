import React from "react";
import { FaTimes } from "react-icons/fa";
import {
  MyModal,
  ModalContainer,
  ButtonClose,
  ModalHeader,
  ModalBody,
  ModalImg,
  Img,
  AcceptButton,
  ModalFooter,
} from "./SubserviceModal.elements";

const SubserviceModal = ({
  isOpen,
  closeModal,
  subservice,
  closeButton = true,
}) => {
  const handleModalContainerClick = (e) => e.stopPropagation();
  return (
    <MyModal isOpen={isOpen} onClick={closeModal}>
      <ModalContainer onClick={handleModalContainerClick} isOpen={isOpen}>
        <ModalHeader>
          <h3>{subservice.subserviceTitle}</h3>
        </ModalHeader>
        {closeButton && (
          <ButtonClose onClick={closeModal}>
            <FaTimes />
          </ButtonClose>
        )}
        <ModalBody>
          {subservice.subserviceImg && (
            <ModalImg>
              <Img
                src={subservice.subserviceImg}
                alt={subservice.subserviceTitle}
              />
            </ModalImg>
          )}
          <p>{subservice.subserviceDescription}</p>
          <ol>
            {subservice.subserviceTask?.map((task, index) => {
              return (
                <li key={index}>
                  {task.taskDescription}
                  <ul>
                    {task.subtask?.map((subtask, index) => {
                      return <li key={index}>{subtask.subtaskDescription}</li>;
                    })}
                  </ul>
                </li>
              );
            })}
          </ol>
        </ModalBody>
        <ModalFooter>
          <AcceptButton onClick={closeModal}>Cerrar</AcceptButton>
        </ModalFooter>
      </ModalContainer>
    </MyModal>
  );
};

export default SubserviceModal;
