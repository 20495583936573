import React from "react";
import {
  FooterContain,
  FooterContainer,
  FooterCopyright,
  FooterHr,
  FooterText,
  FooterTextCopyright,
  SocialList,
  SocialListItem,
  TitleFooterText,
} from "./Footer.elements";
import {
  /* FaTwitter, */
  FaInstagram,
  /* FaFacebook, */
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContain>
        <TitleFooterText>Contáctanos</TitleFooterText>
        <FooterText>Barranquilla, Atántico</FooterText>
        <FooterText>
          <a
            href="mailto:info@advisersandconsultants.com"
            title="Envíanos un correo"
          >
            info@advisersandconsultants.com{" "}
          </a>
        </FooterText>
        <FooterText>
          <a href="tel:+5756657189" title="Llamar">
            +57 (5) 665 7189
          </a>
        </FooterText>
        <FooterText>
          <a href="tel:+573216909100" title="Llamar">
            +57 (321) 690 9100
          </a>
        </FooterText>
        <SocialList>
          {/* <SocialListItem>
            <a href="#" target="_blank">
              <FaTwitter />
            </a>
          </SocialListItem>
          <SocialListItem>
            <a href="#" target="_blank">
              <FaFacebook />
            </a>
          </SocialListItem> */}
          <SocialListItem>
            <a
              href="https://instagram.com/advisersandconsultants?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
          </SocialListItem>
          <SocialListItem>
            <a
              href="https://www.linkedin.com/in/rafael-eduardo-campo-hernandez-a0984651"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
          </SocialListItem>
        </SocialList>
        <FooterHr />
        <FooterCopyright>
          <FooterTextCopyright>
            Copyright © 2022 Advisers & Consultants | Powered by Advisers &
            Consultants
          </FooterTextCopyright>
        </FooterCopyright>
      </FooterContain>
    </FooterContainer>
  );
};

export default Footer;
