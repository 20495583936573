import styled from "styled-components";

export const FooterContainer = styled.section`
  width: 100%;
  background-color: #191023;
  color: #fff;
`;

export const FooterContain = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
`;

export const SocialList = styled.ul`
  position: relative;
  font-size: 15px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  li a {
    color: #fff;
  }
`;

export const SocialListItem = styled.li`
  position: relative;
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 10px 10px;
  line-height: 40px;
  border-radius: 50%;
  color: #fff;
  background-color: #3b2456;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fff;
    transform: scale(1.12);
    transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
    a {
      color: #3b2456;
    }
  }
`;

export const TitleFooterText = styled.p`
  color: #fff;
  text-align: center;
  margin: 1rem;
  font-weight: bold;
`;

export const FooterText = styled.p`
  color: #fff;
  text-align: center;
  margin-bottom: 0.5rem;
  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #ffffff90;
    }
  }
`;

export const FooterHr = styled.hr`
  border-color: rgba(255, 255, 255, 0.1);
  width: 100%;
`;

export const FooterCopyright = styled.div`
  background-color: #0f0a15;
  width: 100%;
`;

export const FooterTextCopyright = styled.p`
  color: #cbcb;
  text-align: center;
  margin: 1rem;
  font-size: 12px;

  a {
    color: #cbcb;
  }
`;
