import React from "react";
import {
  ClientsContainer,
  ImgClients,
  ImgClientsLink,
  ImgContainer,
  ImgLink,
} from "./Clients.elements";
import clientsData from "../../data/clients.js";

const Clients = () => {
  return (
    <ClientsContainer id="clients">
      <h2>Nuestros Clientes</h2>
      <ImgContainer>
        {clientsData?.map((client, index) => {
          if (client.client.clientUrl) {
            return (
              client.client.clientImg && (
                <ImgLink
                  key={index}
                  href={client.client.clientUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ImgClientsLink
                    src={client.client.clientImg}
                    alt={client.client.clientImgAlt}
                  />
                </ImgLink>
              )
            );
          } else {
            return (
              client.client.clientImg && (
                <ImgClients
                  key={index}
                  src={client.client.clientImg}
                  alt={client.client.clientImgAlt}
                />
              )
            );
          }
        })}
      </ImgContainer>
    </ClientsContainer>
  );
};

export default Clients;
