import styled from "styled-components";

export const ServicesContainer = styled.section`
  background-color: #3b2456;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: clamp(2rem, 10vw, 4rem);
    color: #fff;
    text-transform: uppercase;
    margin: 2rem 0;
    padding: 0.5rem;
    box-shadow: 2px 5px #bc9cdf;
    text-align: center;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5vw;
`;

export const ServicesDescription = styled.div`
  color: #fff;
  margin: 0 5vw;
  text-align: center;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const ServicesDescriptionTitle = styled.h3`
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  text-transform: uppercase;
  margin-bottom: 2rem;
  box-shadow: 2px 5px #bc9cdf;
`;

export const ServicesDescriptionText = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin-bottom: 1rem;
`;

export const ServicesImg = styled.img`
  width: 50%;
  max-width: 450px;
  height: auto;
  flex-basis: 0 0 50%;
  margin: 0 5vw;
  margin-bottom: 3rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1.5rem;
    width: 100%;
  }
`;

export const SubservicesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const SubserviceCard = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.5rem;
  max-width: 280px;
  min-height: 570px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const SubserviceTitle = styled.h4`
  font-weight: 900;
  color: #3b2456;
  border-bottom: thin solid #3b2456;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
`;

export const SubserviceImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 250px;
  margin: 1rem;
`;

export const SubserviceDescription = styled.p`
  text-align: justify;
  color: #3b2456;
  font-size: 15px;
`;

export const SubserviceButton = styled.button`
  font-size: 12px;
  margin: 1rem 0.2rem 0 0.2rem;
  padding: 1rem 2rem;
  border: none;
  border-radius: 2rem;
  background: #3b2456; /* 3b2456 */
  color: #fff;
  transition: 0.2 ease-out;

  &:hover {
    color: #ffff;
    background: #000;
    transition: 0.2s ease-out;
    cursor: pointer;
  }
`;

export const ButtonSubservices = styled.button`
  font-size: clamp(0.5rem, 1.5vw, 1rem);
  margin: 1rem 0.2rem 0 0.2rem;
  padding: 1rem 3rem;
  border: none;
  border-radius: 2rem;
  background: #bc9cdf; /* 3b2456 */
  color: #3b2456;
  max-width: 350px;
  height: 80px;
  transition: 0.2 ease-out;
  text-transform: uppercase;

  &:hover {
    color: #000;
    background: #fff;
    transition: 0.2s ease-out;
    cursor: pointer;
  }
`;
