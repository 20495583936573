import styled from "styled-components";

export const ClientsContainer = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: clamp(2rem, 10vw, 4rem);
    color: #000;
    text-transform: uppercase;
    margin: 2rem 0;
    padding: 0.5rem;
    box-shadow: 2px 5px #3b2456; /* #bc9cdf */
    text-align: center;
  }
`;

export const ImgClients = styled.img`
  width: 120px;
  height: auto;
  margin: 1rem;

  @media screen and (max-width: 720px) {
    width: 80px;
    margin: 0.5rem;
  }
`;

export const ImgClientsLink = styled.img`
  width: 120px;
  height: auto;

  @media screen and (max-width: 720px) {
    width: 80px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 1rem 5vw;
`;

export const ImgLink = styled.a`
  text-decoration: none;
  margin: 1rem;
  padding: 0;

  @media screen and (max-width: 720px) {
    margin: 0.5rem;
  }
`;
