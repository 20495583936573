import React from "react";
import {
  ContactButton,
  ContactContain,
  ContactContainer,
  ContactForm,
  ContactList,
  FormContainer,
  InfoContainer,
  ListIcon,
  ListItem,
  ListText,
  LoaderContainer,
  MyHr,
  SocialList,
  SocialListItem,
  TextInfo,
  TextInfoBold,
  TextInfoThin,
  WhatsappLink,
} from "./Contact.elements";

import TextField from "@mui/material/TextField";
import { Alert } from "@mui/material";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaInstagram,
  /*   FaFacebook, */
  FaLinkedin,
} from "react-icons/fa";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFormControls } from "../../hooks/useFormControls";
import Loader from "../Loader/Loader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3b2456",
    },
  },
});

const initialForm = {
  fullName: "",
  email: "",
  phone: "",
  company: "",
  message: "",
};

const Contact = () => {
  const {
    form,
    errors,
    loading,
    response,
    closeResponse,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormControls(initialForm);

  return (
    <ContactContainer id="contact">
      <h2>Contáctanos</h2>
      <ContactContain>
        <FormContainer>
          <ContactForm onSubmit={handleSubmit}>
            <ThemeProvider theme={theme}>
              <TextField
                id="fullName"
                name="fullName"
                label="Nombre Completo *"
                variant="filled"
                value={form.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                {...(errors.fullName && {
                  error: true,
                  helperText: errors.fullName,
                })}
              />
              <br />
              <TextField
                id="email"
                name="email"
                label="Email *"
                variant="filled"
                value={form.email}
                onChange={handleChange}
                onBlur={handleBlur}
                {...(errors.email && {
                  error: true,
                  helperText: errors.email,
                })}
              />
              <br />
              <TextField
                id="phone"
                name="phone"
                label="Teléfono *"
                variant="filled"
                value={form.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                {...(errors.phone && {
                  error: true,
                  helperText: errors.phone,
                })}
              />
              <br />
              <TextField
                id="company"
                name="company"
                label="Organización"
                variant="filled"
                value={form.company}
                onChange={handleChange}
                onBlur={handleBlur}
                {...(errors.company && {
                  error: true,
                  helperText: errors.company,
                })}
              />
              <br />

              <TextField
                id="message"
                name="message"
                label="Mensaje *"
                multiline
                rows={10}
                style={{ width: "100%" }}
                variant="filled"
                value={form.message}
                onChange={handleChange}
                onBlur={handleBlur}
                {...(errors.message && {
                  error: true,
                  helperText: errors.message,
                })}
              />
            </ThemeProvider>
            {loading ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <ContactButton type="submit">Enviar</ContactButton>
            )}
            {response && (
              <Alert severity="success" onClose={closeResponse}>
                Mensaje enviado
              </Alert>
            )}
          </ContactForm>
        </FormContainer>

        <InfoContainer>
          <TextInfo>
            ¿Estás interesado en alguno de nuestros servicios?
          </TextInfo>
          <TextInfoBold>Déjanos tus datos</TextInfoBold>
          <TextInfoThin>
            Nos comunicaremos contigo lo mas pronto posible
          </TextInfoThin>
          <ContactList>
            <ListItem>
              <WhatsappLink
                href="https://wa.me/573216909100?text=Hola%20estoy%20interesado%20en%20sus%20servicios"
                target="_blank"
              >
                <FaWhatsapp /> Escríbenos
              </WhatsappLink>
            </ListItem>
            <ListItem>
              <ListIcon>
                <FaMapMarkerAlt />
              </ListIcon>
              <ListText>Barranquilla, Colombia</ListText>
            </ListItem>
            <ListItem>
              <ListIcon>
                <FaPhone />
              </ListIcon>
              <ListText>
                <a href="tel:+573216909100" title="Llámanos">
                  +57 (321) 690 9100
                </a>
              </ListText>
            </ListItem>
            <ListItem>
              <ListIcon>
                <FaEnvelope />
              </ListIcon>
              <ListText>
                <a
                  href="mailto:info@advisersandconsultants.com"
                  title="Envíanos un correo"
                >
                  info@advisersandconsultants.com
                </a>
              </ListText>
            </ListItem>
            <MyHr />
            <SocialList>
              {/* <SocialListItem>
                <a href="#" target="_blank">
                  <FaTwitter />
                </a>
              </SocialListItem> */}
              {/* <SocialListItem>
                <a href="#" target="_blank">
                  <FaFacebook />
                </a>
              </SocialListItem> */}
              <SocialListItem>
                <a
                  href="https://instagram.com/advisersandconsultants?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </SocialListItem>
              <SocialListItem>
                <a
                  href="https://www.linkedin.com/in/rafael-eduardo-campo-hernandez-a0984651"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
              </SocialListItem>
            </SocialList>
            <MyHr />
          </ContactList>
        </InfoContainer>
      </ContactContain>
    </ContactContainer>
  );
};

export default Contact;
