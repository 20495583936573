import React, { useState } from "react";
import {
  SubservicesContainer,
  ItemsContainer,
  ServicesContainer,
  ServicesDescription,
  ServicesImg,
  SubserviceCard,
  SubserviceTitle,
  SubserviceImg,
  SubserviceDescription,
  ServicesDescriptionTitle,
  ServicesDescriptionText,
  SubserviceButton,
} from "./OurServices.elements";
import { useModal } from "../../hooks/useModal";
import serviceData from "../../data/ourservices.js";
import SubserviceModal from "../SubserviceModal/SubserviceModal";

const OurServices2 = () => {
  let count = 0;
  let reverse = false;

  const [isOpen, closeModal, setIsOpen] = useModal(false);
  const [subservice1, setSubservice1] = useState({});

  return (
    <ServicesContainer id="services">
      <h2>Servicios</h2>
      {serviceData.map((service, index) => {
        count = count + 1;
        if (count % 2 === 0) reverse = true;
        return (
          <ItemsContainer key={index} isReverse={reverse}>
            <ServicesImg src={service.services.serviceImg} order={count} />
            <ServicesDescription>
              <ServicesDescriptionTitle>
                {service.services.serviceTitle}
              </ServicesDescriptionTitle>
              <ServicesDescriptionText>
                {service.services.serviceDescription}
              </ServicesDescriptionText>
              <SubservicesContainer>
                {service.services.subservices.map((subservice, index) => {
                  return (
                    <SubserviceCard key={index}>
                      <SubserviceTitle>
                        {subservice.subserviceTitle}
                      </SubserviceTitle>
                      <SubserviceImg
                        src={subservice.subserviceImg}
                        alt={subservice.subserviceTitle}
                      />
                      <SubserviceDescription>
                        {subservice.subserviceSmallDescription}
                      </SubserviceDescription>

                      <SubserviceButton
                        onClick={() => {
                          setIsOpen(true);
                          setSubservice1(subservice);
                        }}
                      >
                        Ver más...
                      </SubserviceButton>
                    </SubserviceCard>
                  );
                })}
              </SubservicesContainer>
            </ServicesDescription>
          </ItemsContainer>
        );
      })}
      <SubserviceModal
        isOpen={isOpen}
        closeModal={closeModal}
        subservice={subservice1}
      ></SubserviceModal>
    </ServicesContainer>
  );
};

export default OurServices2;
