import React, { useState } from "react";
import logo from "../../assets/images/logo2.png";
import {
  MobileButton,
  MobileNavbar,
  MobileNavItem,
  MobileNavLink,
  MobileNavList,
  Nav,
  NavImg,
  NavItem,
  NavLink,
  NavList,
} from "./Navbar.elements.js";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import { navbarData } from "../../data/navbar.js";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <Nav>
      <Link
        activeClass="active"
        to="hero"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        <NavImg src={logo} alt="Logo" />
      </Link>
      <NavList>
        {navbarData.map(({ navItem }, index) => {
          return (
            <NavItem key={index}>
              <NavLink
                activeClass="active"
                to={navItem.toScroll}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                {navItem.navTitle}
              </NavLink>
            </NavItem>
          );
        })}
      </NavList>
      <MobileButton onClick={() => setOpenMenu(!openMenu)}>
        {!openMenu ? <FaBars /> : <FaTimes />}
      </MobileButton>
      <MobileNavbar openMenu={openMenu} onClick={() => setOpenMenu(!openMenu)}>
        <MobileNavList>
          {navbarData.map(({ navItem }, index) => {
            return (
              <MobileNavItem key={index}>
                <MobileNavLink
                  activeClass="active"
                  to={navItem.toScroll}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  {navItem.navTitle}
                </MobileNavLink>
              </MobileNavItem>
            );
          })}
        </MobileNavList>
      </MobileNavbar>
    </Nav>
  );
};

export default Navbar;
