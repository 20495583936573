import About from "./components/About/About";
import Clients from "./components/Clients/Clients";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import OurServices from "./components/OurServices/OurServices";
import { GlobalStyle } from "./GlobalStyles";

function App() {
  return (
    <>
      <GlobalStyle />
      <Hero />
      <About />
      <OurServices />
      <Clients />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
