import styled from "styled-components";
import ImgAbout from "../../assets/images/about.jpg";

import { Link } from "react-scroll";

export const AboutContainer = styled.section`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)),
    url(${ImgAbout});
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 820px) {
    width: 100%;
    background-attachment: scroll;
  }

  h2 {
    font-size: clamp(2rem, 10vw, 4rem);
    color: #fff;
    text-transform: uppercase;
    margin: 2rem 0;
    padding: 0.5rem;
    box-shadow: 2px 5px #bc9cdf;
    text-align: center;
  }

  p {
    color: #fff;
    margin: 0.5rem 5vw;
    font-size: clamp(1rem, 3vw, 1.5rem);
    text-align: justify;

    b {
      color: #bc9cdf;
    }
  }
`;

export const AboutButton = styled(Link)`
  font-size: 1.4rem;
  margin: 2rem 0;
  padding: 1rem 3rem;
  text-decoration: none;
  background: #3b2456;
  color: #fff;
  transition: 0.2 ease-out;
  text-transform: uppercase;

  &:hover {
    color: #000;
    background: #fff;
    transition: 0.2s ease-out;
    cursor: pointer;
  }
`;
