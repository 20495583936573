import React from "react";
import { AboutButton, AboutContainer } from "./About.elements";

const About = () => {
  return (
    <AboutContainer id="about">
      <h2>Sobre Nosotros</h2>
      <p>
        La acelerada transformación digital, los entornos cambiantes que ha
        venido experimentando el mundo, las empresas, y el fortalecimiento de
        las estrategias de seguimiento, vigilancia y control por parte de las
        entidades del estado; requiere que las organizaciones estén en frecuente
        actualización, adecuación, adaptación e implementación de las nuevas
        tendencias.
      </p>
      <p>
        Somos profesionales con amplia experiencia y trayectoria en el
        suministro de soluciones integrales, eficaces, eficientes e innovadoras
        a través de asesorías y consultorías en{" "}
        <b>GESTION DE INFORMACIÓN Y DEL CONOCIMIENTO</b>, que les permitan a las
        organizaciones alcanzar sus objetivos. Nuestra misión es apoyar a
        nuestros clientes en la generación de valor agregado a sus empresas
        mediante el uso de las mejores prácticas, la experiencia y conocimiento
        de nuestros profesionales.
      </p>
      <p>
        Contamos con un equipo de consultores interdisciplinarios, expertos en
        asesoría e implementación de procesos, minería de datos, inteligencia de
        negocios, gestión de la información, gestión del conocimiento, gerencia
        y ejecución de proyectos, que usan métodos, metodologías, tecnologías
        innovadoras y adaptables.
      </p>
      <AboutButton
        to="contact"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
      >
        Contáctenos
      </AboutButton>
    </AboutContainer>
  );
};

export default About;
