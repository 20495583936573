import imgService1 from "../assets/images/services/service_1.png";
import imgService2 from "../assets/images/services/service_2.png";
import imgSubService1 from "../assets/images/services/subservice_1.png";
import imgSubService2 from "../assets/images/services/subservice_2.png";
import imgSubService3 from "../assets/images/services/subservice_3.png";
import imgSubService4 from "../assets/images/services/subservice_4.png";
import imgSubService5 from "../assets/images/services/subservice_5.png";
import imgSubService6 from "../assets/images/services/subservice_6.png";

export const serviceData = [
  {
    services: {
      serviceTitle: "GESTIÓN DE INFORMACIÓN Y DEL CONOCIMIENTO",
      serviceImg: imgService1,
      serviceDescription:
        "Nuestros métodos de asesoría y consultoría se basan en la ejecución de proyectos enfocados a la obtención, gestión, procesamiento y optimización de la información para obtener de ella el conocimiento para la toma de las mejores decisiones en su organización.",
      subservices: [
        {
          subserviceTitle: "Gestión del Dato",
          subserviceImg: imgSubService1,
          subserviceSmallDescription:
            "En la gestión de la Data se dedica a definir, recolectar, mejorar y garantizar la calidad de los datos capturados para la gestión de su organización.",
          subserviceDescription:
            "En la gestión de la Data se dedica a definir, recolectar, mejorar y garantizar la calidad de los datos capturados para la gestión de su organización, ofrecemos los siguientes servicios:",
          subserviceTask: [
            {
              taskDescription: "Capacitación y entrenamiento:",
              subtask: [
                {
                  subtaskDescription: "Microsoft Office Excel, Access, Word.",
                },
                {
                  subtaskDescription:
                    "Manejo base de Datos (SQL, SQL Server, PL-SQL, Oracle).",
                },
                {
                  subtaskDescription:
                    "Informes ante entes de vigilancia y control (Superintendencia Nacional de salud, contraloría general de la nación, ministerio de salud y protección social, entre otros).",
                },
                {
                  subtaskDescription: "Normatividad Habeas Data.",
                },
              ],
            },
            {
              taskDescription:
                "Diseño, desarrollo e Implementación de mecanismos y herramientas de captura y recolección de datos (Medios físicos, medios digitales, software, Otros).",
              subtask: [],
            },
            {
              taskDescription:
                "Diseño y construcción validadores de Información. (software).",
              subtask: [],
            },
          ],
        },
        {
          subserviceTitle: "Gestión de la Información",
          subserviceImg: imgSubService2,
          subserviceSmallDescription:
            "Cubre las actividades sobre la data capturada en sistemas de información propios de la organización, y su articulación con los objetivos organizacionales.",
          subserviceDescription:
            "Cubre las actividades sobre la data capturada en sistemas de información propios de la organización, y su articulación con los objetivos organizacionales; en este componente ofrecemos los servicios de:",
          subserviceTask: [
            {
              taskDescription: "Consultoría en transformación digital.",
              subtask: [],
            },
            {
              taskDescription: "Gestión y diagnóstico de bases de datos.",
              subtask: [],
            },
            {
              taskDescription: "Implementación procesos integración de datos.",
              subtask: [],
            },
            {
              taskDescription:
                "Diseño e implementación procesos de extracción, transformación y carga de datos.",
              subtask: [],
            },
            {
              taskDescription:
                "Implementación de sistemas de depuración y Validación de Información.",
              subtask: [],
            },
            {
              taskDescription:
                "Asesoría de calidad de información y buen Gobierno del Dato.",
              subtask: [],
            },
            {
              taskDescription:
                "Asesoramiento en selección de plataformas, herramientas y soluciones de información.",
              subtask: [],
            },
            {
              taskDescription:
                "Acompañamiento en la implementación de procesos y software.",
              subtask: [],
            },
            {
              taskDescription: "Diseño e implementación Datawarehouse.",
              subtask: [],
            },
            {
              taskDescription: "Minería de Datos.",
              subtask: [],
            },
            {
              taskDescription:
                "Elaboración de informes a entes de vigilancia y control (Supersalud, Minsalud, DIAN… entre otros).",
              subtask: [],
            },
          ],
        },
        {
          subserviceTitle: "Gestión del Conocimiento",
          subserviceImg: imgSubService3,
          subserviceSmallDescription:
            "Es el más fuerte y potente de todos los componentes pues en él se cimientan los análisis para la toma de decisiones de todos los procesos y niveles que puede requerir su organización.",
          subserviceDescription:
            "Es el más fuerte y potente de todos los componentes pues en él se cimientan los análisis para la toma de decisiones de todos los procesos y niveles que puede requerir su organización. ofrecemos los siguientes servicios:",
          subserviceTask: [
            {
              taskDescription: "Consultoría en transformación digital.",
              subtask: [],
            },
            {
              taskDescription: "Consultorías optimización de Procesos.",
              subtask: [],
            },
            {
              taskDescription: "Bussines Inteligence (B.I).",
              subtask: [
                {
                  subtaskDescription: "Selección, Implementación y soporte.",
                },
                {
                  subtaskDescription: "Creación de reportes.",
                },
                {
                  subtaskDescription: "Capacitación y entrenamiento.",
                },
              ],
            },
            {
              taskDescription:
                "Diseño e implementación de tableros de control para monitoreo de procesos.",
              subtask: [],
            },
            {
              taskDescription:
                "Diseño e implementación de indicadores de control y seguimiento.",
              subtask: [],
            },
            {
              taskDescription:
                "Análisis, diseño y creación modelos analíticos.",
              subtask: [],
            },
            {
              taskDescription: "Análisis Estadístico.",
              subtask: [],
            },
            {
              taskDescription: "Análisis financiero.",
              subtask: [],
            },
            {
              taskDescription: "Georreferenciación y geomarketing.",
              subtask: [],
            },
            {
              taskDescription: "Marketing Digital.",
              subtask: [],
            },
            {
              taskDescription:
                "Asesoría en gestión de la democratización delconocimiento organizacional.",
              subtask: [],
            },
          ],
        },
      ],
    },
  },
  {
    services: {
      serviceTitle: "GESTIÓN DE INFORMACIÓN EN SALUD",
      serviceImg: imgService2,
      serviceDescription:
        "Contamos con un equipo de consultores, con amplia experiencia en implementación de procesos, y elaboración de informes para Entidades Administradores de Planes de Beneficio- EAPB (EPS régimen subsidiado, EPS régimen contributivo, Magisterio), IPS, proveedores de servicios de salud, laboratorios farmacéuticos, distribuidores farmacéuticos y dispositivos médicos, Entes territoriales y demás actores del sector del sector salud.",
      subservices: [
        {
          subserviceTitle:
            "Gestión de información ante entes de vigilancia y control",
          subserviceImg: imgSubService4,
          subserviceSmallDescription:
            "Nuestro equipo de trabajo posee el conocimiento y experiencia para brindarle el apoyo necesario para que este al día con sus procesos y/o reporte de Información.",
          subserviceDescription:
            "Nuestro equipo de trabajo posee el conocimiento y experiencia para brindarle el apoyo necesario para que este al día con sus procesos y/o reporte de Información, tales como:",
          subserviceTask: [
            {
              taskDescription:
                "Resolución 3374 de 2000 y1531 de 2014 (RIPS IPS y EAPB Ministerio de Salud).",
              subtask: [],
            },
            {
              taskDescription:
                "Resolución 4505 de 2012 (Actividades de PE—DT y SP).",
              subtask: [],
            },
            {
              taskDescription:
                "Circular Conjunta 030 de 2013 (Cartera con IPS— Ministerio de Salud).",
              subtask: [],
            },
            {
              taskDescription:
                "Circular Única—(Reporte de Información Financiera Circular 016 Supersalud).",
              subtask: [],
            },
            {
              taskDescription:
                "Indicadores PAMEC—Circular Externa 012 e 2016 Supersalud",
              subtask: [],
            },
            {
              taskDescription:
                "Informes Auditoria cumplimiento Sentencia T760 de 2008.",
              subtask: [],
            },
            {
              taskDescription:
                "Caracterización Poblacional EPS - Res 1536 de 2015",
              subtask: [],
            },
            {
              taskDescription: "Reportes de Indicadores en Salud.",
              subtask: [],
            },
            {
              taskDescription:
                "Estudios de Suficiencia UPC — (Ministerio de Salud).",
              subtask: [],
            },
            {
              taskDescription:
                "Preparación y Consolidación de Información para Reservas Técnicas.",
              subtask: [],
            },
            {
              taskDescription: "Nota(s) Técnica(s) (Operacional y Supersalud).",
              subtask: [],
            },
            {
              taskDescription:
                "Reporte SISMED—Regulación de Precios de Medicamentos.",
              subtask: [],
            },
            {
              taskDescription:
                "Monitoreo de la calidad en salud Resolución 256 de 2016.",
              subtask: [],
            },
            {
              taskDescription:
                "Perfiles Epidemiológicos (Grupos de Riesgo, Quinquenales, OMS, Otros).",
              subtask: [],
            },
            {
              taskDescription:
                "Resolución 273 de 2019 (VIH/SIDA, Gestantes, Sífilis—CAC).",
              subtask: [],
            },
            {
              taskDescription: "Resolución 247 de 2014 (Cáncer—CAC).",
              subtask: [],
            },
            {
              taskDescription:
                "Resolución 3681 de 2013 (Enfermedades Huérfanas—CAC).",
              subtask: [],
            },
            {
              taskDescription:
                "Resolución 123 de 2015 (Hemofilia y otras coagulopatías—CAC).",
              subtask: [],
            },
            {
              taskDescription: "Resolución 2463 de 2014 (ERC5 - HTA - DM—CAC).",
              subtask: [],
            },
            {
              taskDescription:
                "Reportes seguimiento Indicadores organizacionales.",
              subtask: [],
            },
            {
              taskDescription:
                "Reportes seguimiento Ingresos, costos y gastos organizacionales.",
              subtask: [],
            },
            {
              taskDescription: "Análisis estadísticos y epidemiológicos.",
              subtask: [],
            },
            {
              taskDescription:
                "Análisis de riesgos (Circular Externa 004 de 2018).",
              subtask: [],
            },
          ],
        },
        {
          subserviceTitle: "Consultoría y Asesorías",
          subserviceImg: imgSubService5,
          subserviceSmallDescription:
            "Cubre las actividades sobre la data capturada en sistemas de información propios de la organización, y su articulación con los objetivos organizacionales.",
          subserviceDescription:
            "Cubre las actividades sobre la data capturada en sistemas de información propios de la organización, y su articulación con los objetivos organizacionales; en este componente ofrecemos los servicios de:",
          subserviceTask: [
            {
              taskDescription:
                "Consultoría en Evaluación de Procesos de Información.",
              subtask: [],
            },
            {
              taskDescription:
                "Parametrización de Sistemas Integrales de Información.",
              subtask: [],
            },
            {
              taskDescription: "Bussines Inteligence (B.I).",
              subtask: [],
            },
            {
              taskDescription: "Asesoría de Procesos de EAPB:",
              subtask: [
                {
                  subtaskDescription: "Habilitación resolución 2515 de 2018.",
                },
                {
                  subtaskDescription: "Radicación y Auditoria cuentas médicas.",
                },
                {
                  subtaskDescription:
                    "Contratación y gestión de red de servicios.",
                },
                {
                  subtaskDescription: "Generación de Autorizaciones.",
                },
                {
                  subtaskDescription: "Gestión de Recobro.",
                },
                {
                  subtaskDescription: "Seguimiento Riesgos corporativos.",
                },
              ],
            },
            {
              taskDescription: "Auditoria de la Calidad de la Información.",
              subtask: [],
            },
            {
              taskDescription: "Asesoría en Gestión del Riesgo en Salud.",
              subtask: [],
            },
            {
              taskDescription: "Auditoria sistemas gestión de calidad.",
              subtask: [],
            },
          ],
        },
        {
          subserviceTitle: "Evaluación y Auditoria de Procesos",
          subserviceImg: imgSubService6,
          subserviceSmallDescription:
            "Nuestro servicio ha sido diseñado para agregar valor como resultado de las evaluaciones que desarrollamos, nuestra metodología centra la evaluación en las áreas claves del negocio, determinando la integración de los sistemas, procesos y controles.",
          subserviceDescription:
            "Nuestro servicio ha sido diseñado para agregar valor como resultado de las evaluaciones que desarrollamos, nuestra metodología centra la evaluación en las áreas claves del negocio, determinando la integración de los sistemas, procesos y controles.",
          subserviceTask: [
            {
              taskDescription: "Auditoria Externa",
              subtask: [],
            },
            {
              taskDescription: "Auditoria Interna.",
              subtask: [],
            },
            {
              taskDescription: "Auditoría Financiera.",
              subtask: [],
            },
            {
              taskDescription: "Auditoria Administrativa.",
              subtask: [],
            },
            {
              taskDescription: "Auditoría operacional.",
              subtask: [],
            },
            {
              taskDescription: "Auditoria de Sistemas.",
              subtask: [],
            },
            {
              taskDescription: "Auditoria de calidad de información.",
              subtask: [],
            },
          ],
        },
      ],
    },
  },
];

export default serviceData;
