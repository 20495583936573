import styled from "styled-components";
import { Link } from "react-scroll";

export const Nav = styled.nav`
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  box-shadow: 0 -1px 8px #000;
`;

export const NavImg = styled.img`
  width: 100px;
  height: auto;
  cursor: pointer;
`;

export const NavTitle = styled.a`
  color: #3b2456;
  text-decoration: none;
  font-weight: bold;
  font-size: clamp(1rem, 5vw, 2rem);
  right: 0;

  &:hover {
    color: #483a58;
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  color: #3b2456;
  list-style: none;
  margin: 1rem;
  cursor: pointer;
  font-weight: bold;

  @media screen and (max-width: 1014px) {
    margin: 0.5rem;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
  }
`;

export const NavLink = styled(Link)`
  padding: 1rem;
  transition: 0.2s ease;

  &:hover {
    box-shadow: 0 4px 0 -2px #3b2456;
  }

  &.active {
    box-shadow: 0 4px 0 -2px #3b2456;
  }
`;

export const MobileButton = styled.button`
  border-style: none;
  background-color: transparent;
  display: none;
  font-size: 30px;
  margin: 0 1rem;
  color: #3b2456;

  @media screen and (max-width: 800px) {
    display: block;
  }
`;

export const MobileNavbar = styled.div`
  background-color: #3b2456;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: auto;
  left: 0;
  top: ${({ openMenu }) => (openMenu ? "80px" : "-1000px")};
  transition: 0.3s ease-out;
`;

export const MobileNavList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileNavItem = styled.li`
  color: #fff;
  list-style: none;
  margin: 1rem;
  cursor: pointer;
  font-weight: bold;
`;

export const MobileNavLink = styled(Link)`
  padding: 1rem;
  transition: 0.2s ease;

  &:hover {
    box-shadow: 0 4px 0 -2px #fff;
  }

  &.active {
    box-shadow: 0 4px 0 -2px #fff;
  }
`;
