import React from "react";
import Navbar from "../Navbar/Navbar";
import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroItems,
  HeroLema,
  HeroLogo,
  HeroP,
} from "./Hero.elements";
import logo from "../../assets/images/logo3.png";

const Hero = () => {
  return (
    <HeroContainer id="hero">
      <Navbar />
      <HeroContent>
        <HeroItems>
          <HeroLogo src={logo} alt="Logo" />
          <HeroH1>ADVISERS & CONSULTANTS</HeroH1>
          <HeroP>
            Asesores y Consultores en Gestión de la Información y del
            Conocimiento
          </HeroP>
          <HeroLema>¡Potencializamos el valor de tu información!</HeroLema>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
