import { useState } from "react";
/* import { helpHttp } from "../helpers/helpHttp"; */
import Axios from "axios";

export const useFormControls = (initialForm) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const validateForm = (formValues = form) => {
    let tempErrors = { ...errors };
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexPhone =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/im;
    let regexComments = /^.{1,255}$/;

    if ("fullName" in formValues) {
      tempErrors.fullName = "";
      if (!form.fullName.trim()) {
        tempErrors.fullName = "El campo 'Nombre' es requerido";
      } else if (!regexName.test(form.fullName.trim())) {
        tempErrors.fullName =
          "El campo 'Nombre' solo acepta letras y espacios en blanco";
      }
    }

    if ("email" in formValues) {
      tempErrors.email = "";
      if (!form.email.trim()) {
        tempErrors.email = "El campo 'Email' es requerido";
      } else if (!regexEmail.test(form.email.trim())) {
        tempErrors.email = "El campo 'Email' es incorrecto";
      }
    }

    if ("phone" in formValues) {
      tempErrors.phone = "";
      if (!form.phone.trim()) {
        tempErrors.phone = "El campo 'Teléfono' es requerido";
      } else if (!regexPhone.test(form.phone.trim())) {
        tempErrors.phone = "El campo 'Teléfono' es incorrecto";
      }
    }

    if ("message" in formValues) {
      tempErrors.message = "";
      if (!form.message.trim()) {
        tempErrors.message = "El campo 'Mensaje' es requerido";
      } else if (!regexComments.test(form.message.trim())) {
        tempErrors.message =
          "El campo 'Mensaje' no debe exceder los 255 caracteres";
      }
    }

    setErrors({
      ...tempErrors,
    });
  };

  const validateFormSubmit = (form) => {
    let errors = {};
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexPhone =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/im;
    let regexComments = /^.{1,255}$/;

    if (!form.fullName.trim()) {
      errors.fullName = "El campo 'Nombre' es requerido";
    } else if (!regexName.test(form.fullName.trim())) {
      errors.fullName =
        "El campo 'Nombre' solo acepta letras y espacios en blanco";
    }

    if (!form.email.trim()) {
      errors.email = "El campo 'Email' es requerido";
    } else if (!regexEmail.test(form.email.trim())) {
      errors.email = "El campo 'Email' es incorrecto";
    }

    if (!form.phone.trim()) {
      errors.phone = "El campo 'Teléfono' es requerido";
    } else if (!regexPhone.test(form.phone.trim())) {
      errors.phone = "El campo 'Teléfono' es incorrecto";
    }

    if (!form.message.trim()) {
      errors.message = "El campo 'Mensaje' es requerido";
    } else if (!regexComments.test(form.message.trim())) {
      errors.message = "El campo 'Mensaje' no debe exceder los 255 caracteres";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateForm({ [name]: value });
  };

  const ValidateErrors = (errors) => {
    if (errors.fullName || errors.email || errors.phone || errors.message) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateFormSubmit(form));
    let error = ValidateErrors(validateFormSubmit(form));
    if (!error) {
      setLoading(true);
      /* helpHttp()
        .post("http://localhost/sendmail6611/mailer.php", {
          body: form,
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          setResponse(true);
          setForm(initialForm);
          setTimeout(() => setResponse(false), 5000);
        }); */
      Axios.post("https://advisersandconsultants.com/sendmail6611/mailer.php", {
        form,
        token: "28d84bd49b1fbace0055def6a7b0d663",
      })
        .then(function (response) {
          setLoading(false);
          setResponse(true);
          setForm(initialForm);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          setResponse(true);
        });
    } else {
      return;
    }
  };

  const closeResponse = () => setResponse(false);

  return {
    form,
    errors,
    loading,
    response,
    closeResponse,
    handleBlur,
    handleChange,
    handleSubmit,
  };
};
