export const navbarData = [
  {
    navItem: {
      navTitle: "Inicio",
      toScroll: "hero",
    },
  },
  {
    navItem: {
      navTitle: "Nosotros",
      toScroll: "about",
    },
  },
  {
    navItem: {
      navTitle: "Servicios",
      toScroll: "services",
    },
  },
  {
    navItem: {
      navTitle: "Clientes",
      toScroll: "clients",
    },
  },
  {
    navItem: {
      navTitle: "Contacto",
      toScroll: "contact",
    },
  },
];

export default navbarData;
