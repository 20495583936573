import styled from "styled-components";
import ImgBg from "../../assets/images/analytics_1.jpg";

export const HeroContainer = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)),
    url(${ImgBg});
  width: 100%;
  height: auto;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media screen and (max-width: 820px) {
    width: 100%;
    background-attachment: scroll;
  }
`;

export const HeroContent = styled.div`
  height: calc(100vh -80px);
  max-height: 100%;
  width: 100%;
  padding: 0rem calc((100vw - 1600px) / 2);
`;

export const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  max-height: 100%;
  padding: 0 2rem;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
  font-weight: bold;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const HeroH1 = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: clamp(2.5rem, 10vw, 4rem);
  margin-bottom: 1rem;
  box-shadow: 3px 5px #3b2456;
  letter-spacing: 3px;
  font-weight: 900;

  @media screen and (max-width: 360px) {
    font-size: clamp(1.5rem, 5vw, 2rem);
  }
`;

export const HeroP = styled.p`
  font-family: "Signika", sans-serif;
  font-size: clamp(1rem, 1.5vw, 2rem);
  margin-bottom: 2rem;
  font-weight: 100;
  text-transform: none;
`;

export const HeroLema = styled.p`
  font-family: "Signika", sans-serif;
  font-size: clamp(1rem, 1.5vw, 2rem);
  margin-bottom: 2rem;
  font-weight: 100;
  font-style: italic;
  text-transform: none;
  color: #cbcbcb;
  transition: 0.2s ease-out;

  &:hover {
    color: #fff;
  }
`;

export const HeroLogo = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-bottom: 2rem;
`;
