import styled from "styled-components";
import ImgContact from "../../assets/images/contact.jpg";

export const ContactContainer = styled.section`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)),
    url(${ImgContact});
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 820px) {
    width: 100%;
    background-attachment: scroll;
  }

  h2 {
    font-size: clamp(2rem, 10vw, 4rem);
    color: #fff;
    text-transform: uppercase;
    margin: 2rem 0;
    padding: 0.5rem;
    box-shadow: 2px 5px #bc9cdf;
    text-align: center;
  }
`;

export const ContactContain = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff75;
  margin: 1rem 5vw;
  width: 90%;

  @media screen and (max-width: 940px) {
    flex-direction: column-reverse;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  margin: 2rem;
  width: 600px;

  @media screen and (max-width: 940px) {
    width: 100%;
  }

  h2 {
    font-size: clamp(2rem, 10vw, 4rem);
    color: #fff;
    text-transform: uppercase;
    margin: 2rem 0;
    padding: 0.5rem;
    box-shadow: 2px 5px #bc9cdf;
    text-align: center;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.5);
  }
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContactButton = styled.button`
  font-size: 1.4rem;
  margin: 2rem 0;
  padding: 1rem 3rem;
  border: none;
  text-transform: uppercase;
  background: #3b2456;
  color: #fff;
  transition: 0.2 ease-out;

  &:hover {
    color: #000;
    background: #fff;
    transition: 0.2s ease-out;
    cursor: pointer;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoContainer = styled.div`
  padding: 2rem;
  flex-basis: 40%;
  max-width: 800px;

  @media screen and (max-width: 940px) {
    width: 100%;
  }

  @media screen and (max-width: 300px) {
    min-width: 250px;
  }
`;

export const ContactList = styled.ul`
  list-style-type: none;
  /*   margin-left: -30px; */
`;

export const ListItem = styled.li`
  color: #aaa;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
`;

export const ListText = styled.span`
  font: 300 18px "Lato", sans-serif;
  letter-spacing: 1.9px;
  color: #fff;
  margin-top: 1rem;

  @media screen and (max-width: 400px) {
    font: 300 12px "Lato", sans-serif;
  }

  @media screen and (max-width: 300px) {
    font: 300 10px "Lato", sans-serif;
  }

  a {
    color: #fff;
    text-decoration: none;
    transition-duration: 0.2s;

    &:hover {
      color: #3b2456;
      text-decoration: none;
    }
  }
`;

export const ListIcon = styled.span`
  font-size: 1rem;
  color: #3b2456;
  border-radius: 50%;
  padding: 1rem;
  box-shadow: 0 0 0 1px #3b2456;
`;

export const WhatsappLink = styled.a`
  text-decoration: none;
  color: #fff;
  background-color: #2db742;
  border-radius: 2rem;
  padding: 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: 0.2s ease-out;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 14%);
  &:hover {
    transform: scale(1.1);
  }
`;

export const MyHr = styled.hr`
  border-color: rgba(255, 255, 255, 0.6);
`;

export const SocialList = styled.ul`
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  li a {
    color: #fff;
  }
`;

export const SocialListItem = styled.li`
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 10px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: #3b2456;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fff;
    transform: scale(1.12);
    transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
    a {
      color: #3b2456;
    }
  }
`;

export const TextInfo = styled.h3`
  color: #3b2456;
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;

  @media screen and (max-width: 650px) {
    font-size: 20px;
  }
`;

export const TextInfoBold = styled.h3`
  color: #3b2456;
  font-weight: 900;
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  @media screen and (max-width: 650px) {
    font-size: 40px;
  }
`;

export const TextInfoThin = styled.h3`
  color: #3b2456;
  letter-spacing: 1.9px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
`;
